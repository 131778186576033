export default {
    baseURL: 'https://test.leslunettesoho.com',
    // baseURL: 'https://api.leslunettesoho.com',
    login: '/api/v1/auth/login',
    allAgency: '/api/v1/agency/get-agency',
    swich: '/api/v1/auth/switch-agency',
    updatePass: '/api/v1/auth/update-password',
    getProduct: '/api/v1/product/get-products',
    createClient: '/api/v1/client/sell/create-client',
    getClient: '/api/v1/client/sell/get-clients',
    updateClient: '/api/v1/client/sell/update-client',
    createEstimate: '/api/v1/estimate/sell/create-estimate',
    addArticle: '/api/v1/estimate/sell/add-articles',
    createPrice: '/api/v1/price/sell/create-product-price',
    allDevis: '/api/v1/estimate/sell/get-estimate',
    allCompany: '/api/v1/insurance/get-insurance',
    getCommande: '/api/v1/coordination/get-orders',
    getOrder: '/api/v1/coordination/get-paginated-orders',
    validArticle: '/api/v1/coordination/validate-order-article',
    validation: '/api/v1/coordination/validate-order',
    updateArticle: '/api/v1/estimate/sell/update-estimate-article',
    mesure: '/api/v1/estimate/sell/add-estimate-sub-data',
    changePassword: '/api/v1/auth/update-password'
}
